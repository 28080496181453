interface MainBannerProps {
  handleScroll: () => void
}

const MainBanner = ({ handleScroll }: MainBannerProps) => {
  console.info(handleScroll)

  return (
    <a href="/marketplace/triagem">
      <div className="banner-container">
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/logo-mktplace-desktop.png"
          alt="banner marketplace | Decathlon"
          id="desk"
        />
        <img
          src="https://decathlonpro.vteximg.com.br/arquivos/logo-mktplace-mobile.png"
          alt="banner marketplace | Decathlon"
          id="mob"
        />
        <div className="banner-text-wrapper">
          <p>
            O Marketplace da Decathlon é um shopping virtual que oferece a
            oportunidade de você conectar o seu negócio, com a maior loja de
            esportes do mundo! Estamos presentes em mais de 70 países com mais
            de 1.700 lojas ao redor do mundo, sempre com a missão de tornar o
            prazer e os benefícios do esporte acessíveis ao maior número de
            pessoas, de maneira sustentável. Agora, presentes na plataforma
            digital, estamos em busca de parceiros no segmento esportivo para
            proporcionar ainda mais esporte.
          </p>
          <br id="desk" />
          <p>
            E aí? Você topa jogar esse jogo com a gente e se tornar um parceiro
            Decathlon?
          </p>
        </div>
        <button className="banner-cta">cadastre-se</button>
      </div>
    </a>
  )
}

export default MainBanner
