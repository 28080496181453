import { GatsbySeo } from 'gatsby-plugin-next-seo'
import MarketplacePage from 'src/components/marketplace/landingPage/MarketplacePage'
import './styles.scss'

const desc =
  'Quer se tornar um parceiro Decathlon? Cadastre-se no marketplace da maior loja de artigos esportivos e aumente suas vendas!" Visibilidade, Credibilidade, Audiência, Segurança e Atendimento você encontra na Decathlon!'

function Marketplace() {
  return (
    <>
      <GatsbySeo title="Marketplace | Decathlon" description={desc} />
      <MarketplacePage />
    </>
  )
}

export default Marketplace
