import { Lazy, Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import dataPartners from './data/partners.json'

const PartnersCarousel = () => {
  return (
    <div className="partners-container">
      <div className="partners-wrapper">
        <Swiper
          navigation
          breakpoints={{
            1440: {
              slidesPerView: 5,
            },
            1244: {
              slidesPerView: 5,
            },
            1150: {
              slidesPerView: 5,
            },
            1024: {
              slidesPerView: 3,
            },
            1100: {
              slidesPerView: 3,
            },
            1000: {
              slidesPerView: 3,
            },
            768: {
              slidesPerView: 2,
            },
            425: {
              slidesPerView: 2,
            },
            330: {
              slidesPerView: 2,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          spaceBetween={16}
          modules={[Navigation, Lazy]}
          className="marketplace-partners-swiper"
        >
          {dataPartners.map((partner, index) => {
            return (
              <SwiperSlide key={index}>
                <div className="partners-card">
                  <img
                    id="desk"
                    src={partner.image}
                    alt="Partner | Decathlon"
                  />
                  <img
                    style={{ maxWidth: partner.maxWidthMobile }}
                    id="mob"
                    src={partner.image}
                    alt="Partner | Decathlon"
                  />
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default PartnersCarousel
