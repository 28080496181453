const MidiasMktplace = () => {
  return (
    <div className="midias-mkt-container">
      <a href="https://www.facebook.com/decathlonbrasil/">
        <img
          id="desk"
          src="https://decathlonpro.vteximg.com.br/arquivos/facebook-mktplace-lp.png"
          alt="Facebook | Decathlon"
        />
        <img
          style={{ maxWidth: '20px' }}
          id="mob"
          src="https://decathlonpro.vteximg.com.br/arquivos/facebook-mktplace-lp.png"
          alt="Facebook | Decathlon"
        />
      </a>
      <a href="https://www.instagram.com/decathlonbrasil/">
        <img
          id="desk"
          src="https://decathlonpro.vteximg.com.br/arquivos/instagram-mktplace-lp.png"
          alt="Instagram | Decathlon"
        />
        <img
          style={{ maxWidth: '18.33px' }}
          id="mob"
          src="https://decathlonpro.vteximg.com.br/arquivos/instagram-mktplace-lp.png"
          alt="Instagram | Decathlon"
        />
      </a>
      <a href="https://www.youtube.com/c/DecathlonBrasilOficial">
        <img
          id="desk"
          src="https://decathlonpro.vteximg.com.br/arquivos/youtube-mktplace-lp.png"
          alt="YouTube | Decathlon"
        />
        <img
          style={{ maxWidth: '20px' }}
          id="mob"
          src="https://decathlonpro.vteximg.com.br/arquivos/youtube-mktplace-lp.png"
          alt="YouTube | Decathlon"
        />
      </a>
    </div>
  )
}

export default MidiasMktplace
