import { useEffect, useRef } from 'react'

interface FormMktplaceProps {
  handleScrollDown: boolean
}

const FormMktplace = ({ handleScrollDown }: FormMktplaceProps) => {
  const element = useRef<HTMLDivElement | null>(null)

  function handleClick() {
    window.location.href = '/marketplace/triagem'
  }

  useEffect(() => {
    if (handleScrollDown) {
      element?.current?.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }, [handleScrollDown])

  return (
    <div ref={element} className="form-container">
      <div className="form-wrapper">
        <h2 className="form-title">
          Vamos crescer juntos? Seja uma loja parceira!
        </h2>
        <button className="form-button-submit" onClick={() => handleClick()}>
          CADASTRE SUA LOJA
        </button>
      </div>
    </div>
  )
}

export default FormMktplace
