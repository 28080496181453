import dataTips from './data/como-vender.json'

interface SellDecathlonProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
}

const SellDecathlon = ({ setShowModal }: SellDecathlonProps) => {
  return (
    <div className="sell-decathlon-wrapper">
      <h2>Como vender na Decathlon?</h2>
      <div className="sell-decathlon-container">
        {dataTips.map((tip, index) => {
          return (
            <div key={index} className={`tips ${tip.border ? 'border' : ''}`}>
              <h2 style={{ maxWidth: tip.maxWidthTitle }}>{tip.title}</h2>
              {tip.link && (
                <button className="" onClick={() => setShowModal(true)}>
                  {tip.button}
                </button>
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SellDecathlon
