import { Lazy, Navigation, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import dataFeedback from './data/feedback.json'

const FeedbackCarousel = () => {
  return (
    <div className="feedback-mktplace-container">
      <h2>Conheça alguns dos nossos parceiros</h2>
      <div className="feedback-carousel-wrapper">
        <Swiper
          navigation
          breakpoints={{
            1440: {
              slidesPerView: 3,
            },
            1244: {
              slidesPerView: 3,
            },
            1150: {
              slidesPerView: 3,
            },
            1024: {
              slidesPerView: 2,
            },
            1100: {
              slidesPerView: 2,
            },
            1000: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 1,
            },
            425: {
              slidesPerView: 1,
            },
            330: {
              slidesPerView: 1,
            },
          }}
          lazy={{
            loadPrevNext: true,
            loadPrevNextAmount: 1,
          }}
          spaceBetween={14}
          pagination={{ clickable: true }}
          modules={[Navigation, Lazy, Pagination]}
          className="marketplace-feedback-swiper"
        >
          {dataFeedback?.map((feedback, index) => {
            return (
              <SwiperSlide key={index}>
                <div
                  style={{ padding: feedback.padding }}
                  className="feedback-mktplace-card"
                >
                  <img
                    style={{ maxWidth: feedback?.maxWidthImage }}
                    src={feedback?.image}
                    alt="feedback | Decathlon"
                  />
                  <div className="identification-wrapper">
                    <h2>{feedback?.name}</h2>
                    <h3>{feedback?.role}</h3>
                  </div>
                  <p style={{ maxWidth: feedback?.maxWidthFeedback }}>
                    {feedback?.feedback}
                  </p>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>
      </div>
    </div>
  )
}

export default FeedbackCarousel
