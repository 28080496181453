import { useEffect, useState } from 'react'

import ModelModal from '../ModelModal'
import BenefitsCarousel from './BenefitsCarousel'
import FeedbackCarousel from './FeedbackCarousel'
import FormMktplace from './FormMktplace'
import MainBanner from './MainBanner'
import MidiasMktplace from './MidiasMktplace'
import PartnersCarousel from './PartnersCarousel'
import SellDecathlon from './SellDecathlon'

const MarketplacePage = () => {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [scrollDown, setScrollDown] = useState(false)

  const contentModal = {
    title: 'O que é uma plataforma integradora?',
    description:
      'Um integrador serve para auxiliar o controle da sua loja e automatizar todos os processos de venda online em diversos marketplaces. É possível gerenciar seus pedidos, estoque e todos os seus produtos nos diversos canais em um único local.',
  }

  useEffect(() => {
    scrollDown && setScrollDown(false)
  }, [scrollDown])

  return (
    <main className="main-marketplace">
      <MainBanner handleScroll={() => setScrollDown(true)} />
      <BenefitsCarousel />
      <SellDecathlon setShowModal={setShowModal} />
      <FormMktplace handleScrollDown={scrollDown} />
      <FeedbackCarousel />
      <PartnersCarousel />
      <MidiasMktplace />
      {showModal ? (
        <ModelModal
          title={contentModal?.title}
          description={contentModal?.description}
          setShowModal={setShowModal}
        />
      ) : null}
    </main>
  )
}

export default MarketplacePage
